@font-face {
  font-family: 'NohemiLight';
  src: url('../fonts/Nohemi/Nohemi-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
    font-family: 'Nohemi';
    src: url('../fonts/Nohemi/Nohemi-SemiBold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'MeshedDisplay';
    src: url('../fonts/MeshedDisplay/MeshedDisplay-SemiBold.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'LemonMilk';
    src: url('../fonts/LemonMilk/LEMONMILK-BoldItalic.ttf') format('truetype');
}